.login-wrapper {
  height: 100vh;
  min-height: rem-calc(550);
  position: relative;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;

  @include media('<tablet') {
    align-items: center;
  }

  .logo-holder-sm {
    margin-bottom: 35px;
    @include media('>=tablet') {
      display: none;
    }
  }
}

.welcome-screen {
  @include media('<tablet') {
    display: none;
  }

  @include media('>=tablet') {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: rem-calc(15);
    width: 50%;
    position: relative;
    color: $white;
    overflow: hidden;
  }

  @include media('>=desktop') {
    padding: rem-calc(50);
  }

  &:before {
    @include media('>=tablet') {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -2;
      background-color: #1ab3d1;
    }
  }

  .bg-pattern {
    @include media('>=tablet') {
      position: absolute;
      bottom: rem-calc(-50px);
      left: 0;
      right: 0;
      opacity: 0.4;
      z-index: -1;
    }

    img {
      @include media('>=tablet') {
        width: 100%;
      }
    }
  }

  .login-container {
    padding: rem-calc(20);
  }

  .logo-holder {
    @include media('>=tablet') {
      margin-bottom: rem-calc(20);
    }

    img {
      width: 140px;
    }
  }

  h1 {
    @include media('>=tablet') {
      font-size: rem-calc(35);
    }

    @include media('>=widescreen') {
      font-size: rem-calc(40);
    }

    .ganesh-logo {
      font-size: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .icon-ganesh-logo {
      font-size: 80px;
      display: block;
      margin-bottom: 15px;
      margin-top: 25px;
    }

    .ganesh-text {
      display: block;
      width: 100%;
    }
  }

  .login-note {
    @include media('>=tablet') {
      font-size: rem-calc(14);
      line-height: 1.2;
    }
  }

  .login-subtitle {
    @include media('>=tablet') {
      font-size: rem-calc(18);
      font-weight: 500;
      margin-bottom: rem-calc(20);
      display: block;
    }

    @include media('>=desktop') {
      font-size: rem-calc(20);
    }
  }

  a {
    color: $white;

    &:hover {
      color: #373535;
    }
  }
}

.login-screen {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: rem-calc(15);
  margin: 0 15px;

  @include media('>=phone') {
    max-width: rem-calc(400);
    margin: 0 auto;
  }

  @include media('>=tablet') {
    max-width: none;
    marign: 0;
  }

  @include media('<tablet') {
    // background: $white;
    // height: 100%;
    padding: rem-calc(20 15 65);
  }

  .MuiInput-root {
    @include media('<tablet') {
      height: 42px;
    }
  }

  @include media('>=tablet') {
    width: 50%;
  }

  @include media('>=desktop') {
    padding: rem-calc(50);
  }

  .login-footer {
    position: absolute;
    right: calc(15px);
    bottom: 20px;

    @include media('>=tablet') {
      bottom: 30px;
      right: calc(30px);
    }

    @include media('>=widescreen') {
      right: calc(50% - 280px);
    }

    ul {
      @extend %listreset;
      display: flex;
      flex-wrap: wrap;

      li {
        position: relative;
        padding: rem-calc(0 20);
        font-size: 14px;

        &:last-child {
          &:after {
            display: none;
            padding-right: 0;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          border-right: 1px solid #4d4d4d;
        }
      }
    }
  }

  .MuiInputLabel-formControl {
    position: relative;
  }

  .button-holder {
    button {
      margin-right: 18px;
      padding: 6px 25px;

      &.MuiButton-outlined {
        @include media('>=tablet') {
          padding: 10px 16px 10px 15px;
          margin-right: 25px;
        }

        &:hover {
          background-color: #f44336;
          border-color: #f44336;
        }
      }

      @include media('>=tablet') {
        padding: 10px 20px 10px 20px;
      }
    }
  }

  .icon-holder {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $black;
    margin-bottom: 10px;

    svg {
      font-size: 30px;
    }
  }

  h1 {
    margin-bottom: rem-calc(18);
    font-size: rem-calc(28);

    @include media('>=tablet') {
      font-size: rem-calc(35);
      margin-bottom: rem-calc(24);
    }

    @include media('>=desktop') {
      margin-bottom: rem-calc(44);
      font-size: rem-calc(50);
    }
  }

  h2 {
    margin-bottom: 10px;
  }

  .screen-heading {
    margin-bottom: rem-calc(18);
    font-size: 14px;

    @include media('>=desktop') {
      margin-bottom: rem-calc(30);
    }
  }

  .form-group {
    margin-bottom: 18px;
    @include media('>=tablet') {
      margin-bottom: 25px;
    }
  }

  .forget-password-text {
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    margin-top: 10px;

    @include media('>=tablet') {
      margin-top: 15px;
      font-size: 14px;
    }

    @include media('>=desktop') {
      margin-top: 0;
    }
  }

  @include placeholder {
    color: red !important;
    display: block !important;
    opacity: 1 !important;
    font-size: 16px !important;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .login-form {
    width: 90%;
    margin: 0 auto;

    @include media('>=desktop') {
      width: 100%;
    }

    @include media('>=widescreen') {
      width: rem-calc(414);
    }
  }
}
