$gray: #999;
$light-gray: #e5e5e5;
$black: #333;
$primary: #00abcc;
$secondary: #4b5c65;
$white: #fff;
$yellow: #ffc734;
$orange: #ff7d24;
$blue-gem: #402795;
$error: #f44336;
$green: #28b904;

// Headers
$h1-font-size: 30px;
$h2-font-size: 27px;
$h3-font-size: 24px;
$h4-font-size: 21px;
$h5-font-size: 17px;
$h6-font-size: 15px;

$base-link-color: $primary;
// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $black;
$base-background-color: $white;
$font-size-base: 16px !default;
$line-height-base: 1.2 !default;

$animation-speed: 0.3s;
