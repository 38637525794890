.signup-form-wrapper {
  flex: 1;
  padding-bottom: 80px;

  h2 {
    margin-bottom: 40px;
  }

  .label-grid {
    padding: 15px 15px 0 !important;

    .subtitle-label {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .form-buttons {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 15px;
    background-color: #e9f4ff;
    height: 74px;

    @include media('>=tablet') {
      padding: 15px 30px 10px;
    }

    &:before {
      content: '';
      position: absolute;
      top: -1px;
      left: 5px;
      right: 5px;
      border-top: 1px solid rgb(169, 224, 240);

      @include media('>=widescreen') {
        left: 0;
        right: 0;
      }
    }

    .cancel-button {
      &:hover {
        background-color: #ec3232;
        border-color: #ec3232;
      }
    }

    .form-row {
      max-width: 1086px;
      margin: 0 auto;

      @include media('>=tablet') {
        position: absolute;
        bottom: 12px;
        left: 180px;
        @include animate(left);
      }

      .sidebar-toogle & {
        @include media('>=tablet') {
          left: 110px;
        }

        @include media('>=widescreen') {
          left: 130px;
        }
      }
    }
  }

  .MuiInputLabel-formControl {
    position: relative;
  }

  .MuiInputLabel-root {
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  .MuiTextField-root {
    width: 100%;
  }

  .button-holder {
    margin-top: 6px;

    button {
      margin-right: 25px;

      &.MuiButton-outlined {
        @include media('>=tablet') {
          padding: 8px 16px 8px 15px;
        }

        &:hover {
          background-color: #f44336;
          border-color: #f44336;
        }
      }
    }
  }

  .MuiInput-root {
    width: 100%;
  }

  .form-buttons {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 15px;
    background-color: #e9f4ff;
    height: 74px;

    @include media('>=tablet') {
      padding: 15px 30px 10px;
    }

    &:before {
      content: '';
      position: absolute;
      top: -1px;
      left: 5px;
      right: 5px;
      border-top: 1px solid rgb(169, 224, 240);

      @include media('>=widescreen') {
        left: 0;
        right: 0;
      }
    }

    // @include media('>=tablet') {
    //  left: 150px;
    // }

    .cancel-button {
      &:hover {
        background-color: #ec3232;
        border-color: #ec3232;
      }
    }

    .form-row {
      max-width: 1086px;
      margin: 0 auto;

      @include media('>=tablet') {
        position: absolute;
        bottom: 12px;
        left: 200px;
        @include animate(left);
      }

      .sidebar-toogle & {
        @include media('>=tablet') {
          left: 110px;
        }

        @include media('>=widescreen') {
          left: 130px;
        }
      }
    }
  }

  .form-group {
    margin-bottom: 15px;
  }
}
