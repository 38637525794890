#filter-menu {
  .MuiMenu-list {
    width: auto !important;
  }

  .MuiList-padding {
    padding: 5px 0;
  }
}

.MuiTableContainer-root {
  max-height: calc(100vh - 225px) !important;
  min-height: 270px;
  overflow: auto !important;
  scrollbar-width: thin;
}

.table-content-wrapper {
  flex: 1;
  overflow-x: auto;

  padding: rem-calc(30 20 80);

  @include media('>=tablet') {
    padding: rem-calc(30 30 70 38);
  }

  @include media('>=widescreen') {
    padding: rem-calc(30 50 80 58);
  }

  &.user-table-wrapper {
    padding: rem-calc(20 20 80);

    @include media('>=tablet') {
      padding: rem-calc(20 30 70 38);
    }

    @include media('>=widescreen') {
      padding: rem-calc(20 50 80 58);
    }

    .MuiTableContainer-root {
      max-height: calc(100vh - 250px) !important;
    }
  }

  .MuiBackdrop-root {
    z-index: 99 !important;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .export-btn {
    padding: 5px 25px;
  }

  .table-top-header {
    background-color: #f7f7f7;
    padding: rem-calc(6px 15px 5px 0);
    display: flex;
    min-height: 45px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;

    .selected {
      margin: 0 30px 8px 20px;
      display: inline-block;

      @include media('>=tablet') {
        margin: 0 30px 0 20px;
        padding-bottom: 0;
      }
    }
  }

  .tableHeader-left-col {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .MuiButton-root {
      padding: 0 15px;
      color: #4b5c65;
      margin-left: 5px;
      margin-right: 15px;

      &:hover {
        background-color: transparent;
        color: $primary;
        .MuiButton-startIcon {
          color: $primary;
        }
      }

      .MuiButton-startIcon {
        margin-right: rem-calc(7);
        color: #4b5c65;
        @include animate(color);
      }
    }

    .form-control {
      position: relative;
    }

    .icon-search {
      position: absolute;
      top: 9px;
      left: 2px;
      font-size: 14px;
    }

    .MuiInput-input {
      padding: 6px 18px 7px 25px;
    }

    .MuiInput-root {
      border-width: 0 0 1px 0;
      border-color: #a7adb0;
      height: 32px;

      &.Mui-focused {
        border-color: $primary;
      }
    }
  }

  .tableHeader-right-col {
    &.after-checked {
      .delete-btn {
        background-color: $error;
        color: $white;
        margin-left: 15px;

        &:hover {
          background-color: lighten($error, 5%);
        }

        svg {
          font-size: 16px;
        }

        .MuiButton-startIcon {
          margin-right: 5px;
        }
      }
      .export-btn-holder {
        display: inline-block !important;
      }
      button {
        padding: 4px 15px;
        font-size: 14px;
        background-color: $white;
        color: $primary;
        margin-left: 15px;
        margin-bottom: 5px;

        @include media('>=tablet') {
          margin-bottom: 0;
        }

        &:hover {
          background-color: $primary;
          color: $white;
        }
      }

      .export-btn {
        background-color: $primary;
        color: $white;

        &:hover {
          background-color: lighten($primary, 10%);
        }
      }
    }

    .export-btn {
      padding: 4px 15px;
      font-size: 14px;
    }
  }

  tbody {
    tr {
      th,
      td {
        background-color: $white;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:nth-child(even) {
        th,
        td {
          background-color: #f0fbfe;
        }
      }

      td {
        &:first-child {
          min-width: 50px;
          width: 50px;
          position: sticky;
          left: 0;
        }

        &:nth-child(2) {
          min-width: 50px;
          width: 50px;

          @include media('>=tablet') {
            left: 35px;
            position: sticky;
          }
        }

        &:nth-child(3) {
          @include media('>=tablet') {
            left: 80px;
            position: sticky;
          }
        }
      }
    }
  }

  td,
  th {
    border: 0;
    max-width: 200px;
  }

  thead {
    position: sticky;
    z-index: 9;
    top: 0;

    select {
      height: 30px;
      background: $white;
      border: 1px solid #dcdada;
      margin-top: -5px;
      width: 100%;
      font-weight: 300;
      color: #333;
      padding: 6px 10px 7px;
      &:focus-visible {
        outline: none;
      }
    }

    th {
      background-color: #f7f7f7;
      min-width: 170px;
      padding: 8px 16px 12px;
      font-weight: 500;
      vertical-align: top;
      font-size: 14px;

      &:first-child {
        vertical-align: middle;
      }

      &:first-child {
        min-width: 50px;
        width: 50px;
        position: sticky;
        left: 0;
        z-index: 10;
      }

      &:nth-child(2) {
        min-width: 50px;
        width: 50px;
        @include media('>=tablet') {
          position: sticky;
          z-index: 10;
          left: 35px;
        }
      }

      &:nth-child(3) {
        @include media('>=tablet') {
          left: 80px;
          position: sticky;
          z-index: 10;
        }
      }
    }
  }

  .MuiTableCell-paddingCheckbox {
    position: sticky;
    left: 0;
    min-width: 50px;
    z-index: 1;
  }

  .MuiTableCell-sizeSmall {
    padding: 6px 20px 6px 16px;
  }

  .MuiInput-root {
    height: 30px;
    margin-top: -5px !important;
  }
}

.pagination {
  padding: 15px 0;
  align-items: center;

  @include media('>=tablet') {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 10px 15px 20px;
  }
}

.pagination-counter {
  @include media('>=tablet') {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 80%;
  }

  select {
    background: $white;
    border: 1px solid #dcdada;
    padding: 7px 5px;
  }

  .form-group {
    margin-bottom: 15px;

    @include media('>=tablet') {
      margin-right: 15px;
      margin-bottom: 0;
    }
  }

  .pagination-title {
    margin-right: 15px;
    font-weight: 500;
  }
}

.default-filter {
  background-color: #fff;
}

.user-table-wrapper {
  tbody {
    tr {
      td {
        &:nth-child(2) {
          min-width: 170px !important;
          width: auto !important;
        }
      }
    }
  }

  thead {
    th {
      &:first-child {
        min-width: 50px;
        width: 50px;
      }
      &:nth-child(2) {
        min-width: 170px !important;
        width: auto !important;
      }
    }
  }
}
