.dashboard-section {
  flex: 1;
  overflow-x: auto;

  .badge {
    padding: 3px 5px;
    background-color: rgba(#4b5c65, 20%);
    width: 70px;
    flex-basis: 70px;
    text-align: center;
    border-radius: 12px;
    font-size: 12px;
  }

  .calender-box {
    input {
      border-radius: 0 !important;
      border: 1px solid $gray;
      height: 42px;
      padding: 5px 5px 5px 10px;
    }
  }

  .graph-holder {
    margin-top: 30px;
    canvas {
      margin: 0 auto;
      height: auto !important;
      width: 100% !important;

      @include media('>=phone') {
        height: 250px !important;
        width: 400px !important;
      }

      @include media('>=768px') {
        height: auto !important;
        width: 100% !important;
      }

      @include media('>=1200px') {
        height: 250px !important;
        width: 420px !important;
      }
    }
  }

  .denote {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin: 10px 0 0;
    color: #4b5c65;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 40px;
      right: 0;
      border-bottom: 1px solid rgba($secondary, 50%);
    }

    .denote-text {
      display: inline-block;
      background-color: #e5f7fa;
      position: relative;
      padding: 0 15px;
      z-index: 2;
    }
  }

  .canvas-md {
    margin: 0 25px;
  }

  .grid-holder {
    margin-bottom: 20px;
  }

  ul {
    @extend %listreset;
  }

  li {
    position: relative;
    padding: 12px 0 15px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid rgba(#4b5c65, 40%);

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    p {
      margin-bottom: 0;
    }

    &.status-urgent {
      &:before {
        background-color: $error;
      }
    }

    &.status-medium {
      &:before {
        background-color: $yellow;
      }
    }

    &.status-low {
      &:before {
        background-color: $green;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 18px;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $black;
    }
  }

  .listbox-body {
    .list-holder {
      max-height: 305px;
      overflow-y: auto;
      scrollbar-width: thin;
    }

    .list-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
      margin-right: 15px;
      font-size: 14px;
      a {
        color: $black;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .listbox-head {
    display: flex;
    flex-wrap: wrap;
    background-color: rgba($primary, 10%);
    padding: 10px;
    font-weight: 500;

    .left-col {
      flex: 1;
    }

    .right-col {
      width: 55px;
      flex-basis: 55px;
    }
  }
}

.dashboard-content {
  padding: rem-calc(20 20 80);

  @include media('>=tablet') {
    padding: rem-calc(20 30 70 38);
  }

  @include media('>=widescreen') {
    padding: rem-calc(20 50 80 58);
  }
}
.dashboard-top-bar {
  padding: rem-calc(30 20 0);

  @include media('>=phone') {
    padding: rem-calc(40 20 0);
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }

  @include media('>=tablet') {
    padding: rem-calc(50 30 0 38);
  }

  @include media('>=widescreen') {
    padding: rem-calc(50 50 0 58);
  }

  .MuiSelect-select {
    width: 120px;
  }

  button {
    padding: 7px 15px;
    margin-left: 18px;
  }

  .top-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.dashboard-box {
  border: 1px solid $primary;
  padding: 20px 20px;

  &.h-100 {
    height: 100%;
  }

  .chart-holder {
    margin-bottom: 30px;
  }

  &.bg-primary {
    background-color: rgba($primary, 10%);
  }

  .box-heading {
    margin-bottom: 15px;

    .title {
      margin-bottom: 5px;
      font-weight: 500;
    }

    .count-number {
      font-size: 18px;
      display: inline-block;
    }
  }

  .detail-holder {
    display: flex;
    flex-wrap: wrap;

    .box-detail {
      width: 33.33%;
    }
  }

  .box-detail {
    .title {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .number {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .title {
    display: block;
    text-transform: capitalize;
  }

  .number {
    display: inline-block;
  }
}

.two-col-box {
  display: flex;
  flex-wrap: wrap;

  .box-heading {
    margin-bottom: 50px;
  }

  .box-detail {
    margin-bottom: 30px;
  }

  .left-col {
    @include media('>=desktop') {
      width: 100%;
    }

    @include media('>=widescreen') {
      width: 50%;
    }
  }

  .right-col {
    @include media('>=desktop') {
      width: 100%;
    }

    @include media('>=widescreen') {
      width: 45%;
    }

    .chart-holder {
      margin-top: 35px;
    }
  }
}

.total-client-holder {
  .title {
    position: relative;
    padding-left: 18px;

    &:before {
      content: '';
      position: absolute;
      width: 12px;
      height: 10px;
      left: 0;
      top: 52%;
      transform: translateY(-50%);
      background-color: $primary;
    }

    &.prospect {
      &:before {
        background-color: #bed643;
      }
    }

    &.others {
      &:before {
        background-color: $orange;
      }
    }
  }
}

.dashboard-alert-text {
  display: flex;
  align-items: center;

  .text-holder {
    text-align: center;
    max-width: 790px;
    margin: 0 auto;
    padding: 50px;
    border-radius: 20px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    box-shadow: 32px 32px 64px #ebebeb, -32px -32px 64px #ffffff;
  }

  .icon-holder {
    margin-bottom: 20px;

    svg {
      color: $error;
      font-size: 70px;

      @include media('>=phone') {
        font-size: 100px;
      }
    }
  }
}
.assignedToIndashboardtxt {
  color: black;
  font-weight: 500;
  font-size: 11px;
}
.assignedToIndashboard {
  font-size: 12px;
}
