// Typography
body {
  color: $black;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-weight: 500;
  margin: 0 0 0.5em;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: $h1-font-size;

  @include media(">=tablet") {
    font-size: 50px;
  }
}

h2,
.h2 {
  font-size: $h2-font-size;

  @include media(">=tablet") {
    font-size: 36px;
  }
}

h3,
.h3 {
  font-size: $h3-font-size;

  @include media(">=tablet") {
    font-size: 28px;
  }
}

h4,
.h4 {
  font-size: $h4-font-size;

  @include media(">=tablet") {
    font-size: 20px;
  }
}

h5,
.h5 {
  font-size: $h5-font-size;

  @include media(">=tablet") {
    font-size: 16px;
  }
}

h6,
.h6 {
  font-size: $h6-font-size;

  @include media(">=tablet") {
    font-size: 14px;
  }
}

p {
  margin: 0 0 16px;
  line-height: 1.5;
}

a {
  color: $base-link-color;
  @include animate(color background-color background opacity);
  text-decoration: none;

  &:hover,
  &:focus {
    color: darken($base-link-color, 10%);
    outline: none;
    text-decoration: none;
  }
}
