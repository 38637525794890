.events-wrapper {
  padding: rem-calc(40 20 80);
  flex: 1;
  height: 90vh;
  @include media('<tablet') {
    overflow-x: auto;
  }

  @include media('>=tablet') {
    // padding: rem-calc(50 30 70);
  }

  .dx-scheduler-navigator-previous,
  .dx-scheduler-navigator-next {
    display: none;
  }

  .dx-scheduler-navigator-caption {
    background-color: transparent;
    border: 0;
    position: relative;

    &:after {
      content: '\e90a';
      font-family: 'icomoon';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 8px;
      color: $secondary;
    }
  }

  .dx-scheduler-header {
    background-color: transparent;
    border-width: 0 0 1px 0;
    overflow: hidden;
  }

  .dx-scheduler-work-space {
    margin-top: 0;
    padding-top: 0;
    background: linear-gradient(105deg, #eeeeee, #ffffff);
    box-shadow: 10px 10px 40px #dddddd, -10px -10px 40px #ffffff;
  }

  .dx-scheduler {
    border-radius: 20px;
    background: linear-gradient(105deg, #eeeeee, #ffffff);
    box-shadow: 10px 10px 40px #dddddd, -10px -10px 40px #ffffff;
    height: auto;
  }

  .dx-tabs {
    border-top-right-radius: 20px;
    background: linear-gradient(105deg, #e6e6e6, #ffffff);
    box-shadow: 10px 10px 40px #d9d9d9, -10px -10px 40px #ffffff;
    overflow: hidden;
  }

  .dx-scheduler-appointment {
    background-color: rgba($primary, 0.9);
  }

  .dx-scheduler-date-table-row {
    td {
      padding: 0 10px;
    }
  }

  .dx-scheduler-work-space-work-week {
    .dx-scheduler-all-day-title {
      top: 50px;
    }
  }

  .dx-scheduler-work-space-day:not(.dx-scheduler-work-space-grouped) {
    .dx-scheduler-all-day-title {
      top: 1px;
    }
  }

  .dx-scheduler-all-day-panel {
    background-color: transparent;
  }
}
.dx-scheduler-small .dx-scheduler-view-switcher.dx-tabs {
  display: inline-block !important;
}
.selfEvent {
  .dx-scheduler-appointment {
    background-color: $orange !important;
  }
}

.dx-scheduler-date-table-current-date {
  background-color: rgba($secondary, 0.1);

  .dx-scheduler-date-table-cell-text {
    color: $green;
  }
}
