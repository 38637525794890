.verify-email-holder {
  background-color: #f7f7f7;
  height: 100vh;
  min-height: 550px;

  .verify-header {
    padding: 15px 0;
    position: absolute;
    left: 0;
    display:flex;
    .ganesh-logo{
      font-size: 50px!important;
      color:$primary;
      margin-left:15px;
    }
  }

  .container {
    height: 100%;
    position: relative;
  }

  .logo-holder {
    width: 120px;
  }

  .verify-content {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 100px 0 50px;

    .content-holder {
      max-width: 550px;
      margin: 0 auto;
      text-align: center;
      background: linear-gradient(145deg, #dedede, #ffffff);
      box-shadow: 20px 20px 60px #d2d2d2, -20px -20px 60px #ffffff;
      border-radius: 0 0 50px 50px;
      padding: 50px 30px;
    }

    .sm-text {
      font-size: 14px;
      display: inline-block;
      margin-top: 10px;
    }

    .MuiButton-root {
      margin: 15px 0 !important;
    }

    .icon-holder {
      width: 60px;
      margin: 0 auto 20px;
    }
  }
}

.email-confirm {
  label {
    margin: 9px 0px;
  }
}

h2.MuiTypography-root.MuiTypography-h6 .centerbetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

