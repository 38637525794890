.email-management-wrapper {
  flex: 1;
  .category-submit-button {
    button {
      margin-right: 20px;
    }

    .cancel-button {
      &:hover {
        background-color: $error;
        border-color: $error;
      }
    }
  }

  .back-btn {
    .MuiButton-startIcon {
      margin-right: 0;
    }
    &:hover {
      background: transparent;
      color: $error;
    }
  }

  .button-holder {
    padding: 60px 0 0;
    width: 100%;

    .MuiButton-root {
      margin-right: 15px;
    }
  }

  .MuiInputLabel-formControl {
    position: relative;
  }

  .textarea-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    display: block;

    &.p-0 {
      padding-top: 0;
    }
  }

  .category-list {
    padding: 5px 0 5px 15px;
    position: relative;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }

  .select-category {
    padding-bottom: 50px;

    h2 {
      margin-bottom: 30px;
    }
  }

  .view-btn-holder {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 33px;
    cursor: not-allowed;
    button {
      background-color: #f9f9f9;
      padding: 8px 20px;
      color: #00abcc;
      width: 100%;
      @include media('>=phone') {
        margin-left: 25px;
      }
      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }

  textarea {
    padding: 15px;
    resize: none;
    width: 100%;
    background-color: transparent !important;
    border: 1px solid #dcdada;

    &:focus {
      border-color: $primary;
      outline: none;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .email-list-autocomplete {
    .MuiInput-root {
      height: auto !important;
      min-height: 40px;
    }
  }

  .add-category-form {
    padding: 25px 20px;
  }
}

.MuiAutocomplete-popper {
  .Mui-checked {
    color: $primary !important;
  }
}

.template-wrapper {
  flex: 1;

  .no-record {
    margin-left: 1.2rem;
  }

  h2 {
    margin-bottom: 40px;

    @include media('<desktop') {
      font-size: 28px;
    }
  }

  .template-list-item {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $light-gray;
    position: relative;
    min-height: 300px;
    padding: 0.5rem;
    border-radius: 3px;
    cursor: pointer;
    transition: border 0.1s ease-in;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .loading {
      position: absolute;
      top: 3px;
      right: 3px;
    }

    .check-icon {
      position: absolute;
      bottom: 0px;
      right: 0px;
      color: $primary;
      transition: all 0.15s ease-in;
    }
    .hide-icon {
      display: none;
    }
    .show-icon {
      display: block;
    }

    .template-image {
      // height: 22rem;
      // width: 100%;
      // object-fit: scale-down;
      height: 22rem;
      object-fit: cover;
      object-position: center;
      margin-top: -1rem;
    }
  }
  .active-template {
    border: 2px solid $primary;
  }

  .template-name {
    display: block;
    text-align: center;
    font-size: px;
    color: $secondary;
    font-weight: 500;
    padding: 10px 15px;
    transition: color 0.1s ease-in;
  }

  .active-template-name {
    color: $primary;
  }
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color: rgb(250, 250, 250);
  padding: 0 0.25rem 2rem;
  border: 1px solid rgb(211, 209, 209);
}
.toolbar-class {
  border: 1px solid #ccc;

  a {
    color: black;
  }
}
.preview {
  padding: 1rem;
  margin-top: 1rem;
}

.email-content-wrapper {
  display: flex;
  flex-wrap: wrap;

  .more-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    opacity: 0;
    visibility: hidden;
    @include animate(opacity visibility);

    &:hover {
      color: $primary;
    }
  }

  .list {
    position: relative;

    .MuiButton-text {
      padding: 5px 0;
    }

    .MuiButton-root {
      min-width: 10px;

      .MuiTouchRipple-root {
        display: none;
      }

      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      .more-icon {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .select-category {
    margin-top: 75px;
    position: relative;
    width: 100%;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: -8%;
      left: 0;
      z-index: -1;
      right: 0;
      bottom: 0;
      background-color: rgb(250, 250, 250);
    }
    @include media('>=phone') {
      padding-left: 35px;
      height: 100%;
      width: 210px;
    }

    @include media('>=tablet') {
      width: 260px;
    }

    @include media('>=desktop') {
      width: 220px;
    }

    @include media('>=1300px') {
      width: 250px;
    }

    .MuiFormControlLabel-root {
      max-width: 100%;
    }

    .MuiIconButton-label {
      svg {
        font-size: 22px;
      }
    }

    .MuiFormControlLabel-label {
      font-size: 15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.category-menu {
  .MuiMenu-list {
    width: 150px !important;
  }
}

.category-list-dialog {
  .MuiDialog-paperWidthSm {
    width: 500px;
  }
}
.iconCategory {
  &:hover {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .iconCategory-one {
    transition: all 0.2s ease;
    &:hover {
      color: $primary;
    }
  }
  .iconCategory-Two {
    transition: all 0.2s ease;
    &:hover {
      color: $error;
    }
  }
}
.MuiGrid-spacing-xs-3 {
  width: calc(100% + 0px);
  margin: -18px;
}
