button {
  .MuiButton-startIcon {
    margin-right: 13px;

    .MuiSvgIcon-root {
      font-size: 22px;
    }
  }
}

.cancel-button {
  @include animate(background-color border-color);
  &:hover {
    background-color: $error !important;
    border-color: $error !important;
    color: white;
  }
}
.Ondisable {
  cursor: pointer !important;
  color: gray !important;
  background-color: rgba(80, 80, 80, 0.219) !important;
  &:hover {
    cursor: pointer;
  }
}
.NotDisable {
  background-color: #00abcc !important;
  color: #fff !important;
}
.cancel-button {
  background: $error !important;
  color: #fff !important;
  .MuiButton-startIcon {
    margin-right: 13px;

    .MuiSvgIcon-root {
      font-size: 27px;
      fill: #333;
    }
  }

  &:hover {
    opacity: 0.7;
    .MuiSvgIcon-root {
      fill: #fff;
    }
  }
}

.btn-secondary {
  background-color: #f7f7f7 !important;
  padding: 8px 20px !important;
  color: $primary !important;

  &:hover {
    background-color: $primary !important;
    color: $white !important;
  }
}
.btn-secondary-xs {
  background-color: #f7f7f7 !important;
  padding: 1px 5px !important;
  color: $primary !important;
  font-size: 0.85rem !important;

  .MuiButton-startIcon {
    margin: 0 !important;
  }

  &:hover {
    background-color: $primary !important;
    color: $white !important;
  }
}

.alert-btn-holder {
  margin: 10px 10px 10px 0;

  button {
    padding: 5px 15px;
    margin-left: 15px !important;
  }
}

.delete-button {
  &.MuiButton-contained {
    background: $error;
    color: $white !important;

    &:hover {
      background-color: darken($error, 10%);
    }
  }
}

.cancel-button-popup {
  background-color: $error;
  // color
}
.onlyForUpdate {
  padding: 6px 20px !important;
}
.forUpdates {
  padding: 6px 11px !important;
}
.forClearHover {
  background: transparent;
  &:hover {
    background: $error !important;
    outline-color: transparent !important;
    border-color: transparent !important;
    color: #fff;
  }
}
.onlyForBig {
  padding: 10px 22px 10px 25px !important;
}
