.MuiFormHelperText-root {
  font-size: 14px !important;
  color: #333 !important;
}

.MuiInputLabel-formControl {
  transform: none !important;
}

input {
  &:focus {
    outline: none;
  }
}

.form-buttons {
  button {
    margin-right: 21px;
  }
}

.MuiSelect-icon {
  display: none !important;
}

.MuiSelect-select {
  position: relative;

  &:after {
    content: '\e90a';
    font-family: 'icomoon';
    position: absolute;
    right: 14px;
    font-size: 8px;
    color: #333;
    top: 50%;
    transform: translateY(-50%);
  }
}

.MuiAutocomplete-listbox {
  min-width: 230px;

  li {
    &:hover {
      background-color: #4b5c65 !important;
      color: #fff !important;
    }
  }
}

.MuiAutocomplete-inputRoot {
  position: relative;
  padding: 0 !important;

  .MuiAutocomplete-input {
    padding: 1px 40px 1px 18px !important;
    height: 100%;
  }

  &:after {
    content: '\e90a';
    font-family: 'icomoon';
    position: absolute;
    right: 14px;
    font-size: 8px;
    color: #333;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }

  .MuiIconButton-root {
    display: none;
  }
}

.MuiInputLabel-root {
  position: relative !important;
  margin-bottom: 10px !important;
}

.MuiInputLabel-formControl {
  position: relative;
}

.textarea-label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-top: 12px;
  color: #333;
  display: inline-block;

  &.mb-sm {
    margin-bottom: 5px;
  }

  &.p-0 {
    padding-top: 0;
  }
}

textarea {
  padding: 15px;
  resize: none;
  width: 100%;
  background-color: transparent !important;
  border: 1px solid #dcdada;

  &:focus {
    border-color: #00abcc;
    outline: none;
  }
}
.MuiDialog-paperWidthSm-big {
  width: 400px !important;
  height: 400px !important;
  /* max-width: 480px; */
}
.MuiIconButton-root-big {
  float: right;
}
.MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 18px 24px 0px;
}
.tab-panel-details-1 {
  margin-top: 44px;
  padding-top: 20px;
  border-top: 2px solid #00000024;
}
.MuiBox-root {
  margin-bottom: 20px;
  text-align: left;
}
// .MuiDialogTitle-root-big {
//   display: flex;
//   align-items: center;
// }
