.side-nav {
  background-color: $secondary;
  text-align: center;
  width: 155px;
  padding: rem-calc(5 0 5);
  @include animate(width right);
  z-index: 999;
  @include media('<tablet') {
    position: absolute;
    top: rem-calc(0);
    bottom: 0;
    right: -999px;
    min-height: 100vh;
  }

  @include media('>=tablet') {
    padding: rem-calc(0 0 50);
    position: relative;
    min-height: calc(100vh - 90px);
  }

  .sidebar-toogle & {
    @include media('<tablet') {
      right: 0;
    }
    @include media('>=tablet') {
      width: 80px;

      .icon-holder {
        margin-bottom: 0;
      }

      .nav-icon {
        font-size: 30px;
      }

      .icon-envelope {
        font-size: rem-calc(40);
      }

      .nav-list {
        a {
          padding: 10px;
          flex-direction: row-reverse;
        }
      }

      .nav-title {
        opacity: 0;
        visibility: hidden;
        position: absolute;
      }

      .list {
        height: 80px;
      }
    }
  }

  .profile-picture {
    margin: 0 0 0 10px;

    @include media('>=tablet') {
      display: none;
    }

    .MuiAvatar-circle {
      margin: 0 auto;
    }

    a {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .list {
    @include animate(height);
    // height: 165px;

    &:first-child {
      a {
        padding: rem-calc(15 5);

        @include media('>=tablet') {
          padding: rem-calc(20 5 25);
        }
      }
    }
  }
  .nav-list {
    a {
      padding: rem-calc(15 5);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      color: $white;

      @include media('>=tablet') {
        padding: rem-calc(27 5);
      }

      &.list-active {
        background-color: $primary;

        &:hover {
          background-color: $primary;
        }
      }

      &:hover {
        background-color: rgba($primary, 0.4);
      }
    }
  }

  .icon-holder {
    margin-bottom: rem-calc(12);
    width: 100%;

    @include media('>=tablet') {
      margin-bottom: rem-calc(15);
    }
  }

  .nav-icon {
    font-size: rem-calc(23);

    @include media('>=tablet') {
      font-size: rem-calc(35);
    }
  }

  .icon-envelope {
    font-size: rem-calc(30);

    @include media('>=tablet') {
      font-size: rem-calc(40);
    }
  }

  .nav-title {
    display: block;
    font-weight: 500;
    @include animate(opacity transform);
    text-transform: capitalize;
    width: 100%;
    font-size: 15px;
    padding: 0;
    // width: 150px;
  }
}

.top-menu {
  display: flex;
  padding: 15px 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-bottom: 1px dashed rgb(201, 201, 201);

  @include media('>=tablet') {
    display: none;
  }

  .search-sm {
    margin-right: 10px;
    margin-top: 5px;

    a {
      color: $white;
      font-size: 22px;
    }
  }
}
