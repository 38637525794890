$breakpoints: (
  'xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);
.MuiDialog-paperWidthSm {
  z-index: 100 !important;
}
.fAAXMb {
  width: 213px !important;
}
@import 'vendors/include-media';
@import 'vendors/normalize';
@import 'vendors/icomoon';

@import 'base/mixins';
@import 'base/functions';
@import 'base/forms';
@import 'base/variables';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/resets';
@import 'base/typography';

@import 'components/buttons';
@import 'components/profileForm';
@import 'components/sidebar';
@import 'components/common';
@import 'components/login';
@import 'components/header';
@import 'components/table';
@import 'components/clientDetail';
@import 'components/signup';
@import 'components/verifyEmail';
@import 'components/email-management';
@import 'components/dashboard';
@import 'components/event-management';
@import 'components/user-detail';
