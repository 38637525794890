body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Maven Pro', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Maven Pro, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  overflow: hidden;
  min-height: 100vh;
}

.MuiBackdrop-root {
  background-color: transparent !important;
  z-index: 9 !important;
}

.color-primary {
  color: $primary !important;
}

.container {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 20px;
}

.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.content-section {
  max-width: rem-calc(1400);
  margin: 0 auto;
  padding: rem-calc(40 20 80);
  flex: 1;
  // min-height: 100vh;

  @include media('>=tablet') {
    padding: rem-calc(50 30 70 38);
  }

  @include media('>=widescreen') {
    padding: rem-calc(50 50 80 58);
  }
}

.sidebar-toggle-wrapper {
  margin-left: rem-calc(15);

  @include media('>=phone') {
    margin-left: rem-calc(20);
  }

  @include media('>=tablet') {
    display: none;
  }
}

.sidebar-toggle-wrapper-desktop {
  margin-left: 10px;
  position: fixed;
  bottom: 15px;
  left: 40px;
  width: 50px;
  height: 50px;
  z-index: 99;
  border-radius: 50%;
  background: $black;
  @include animate(left);

  .sidebar-toogle & {
    left: 4px;
  }

  .opener {
    margin: 6px 7px;
  }

  @include media('<tablet') {
    display: none;
  }
}

.upload-box {
  border: 1px solid #dadada;
  padding: rem-calc(45 50 35);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .box-content {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }

  img {
    margin-bottom: 24px;
  }

  h4 {
    font-size: 20px;
    color: #333;
    margin-bottom: 12px;
  }
}

.follow-upnote-label {
  line-height: 1 !important;
  padding-top: 4px !important;
}

.fileupload-content {
  img {
    margin-bottom: 20px;
  }

  .upload-subtitle {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px !important;
  }
}

.Mui-error {
  border-color: $error !important;
  color: $error !important;
}

.subtitle-label-referral {
  margin-bottom: 6px !important;
  padding-top: 0;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
}

.text-danger {
  color: $error;
}

.hidden {
  display: none !important;
}

.toast-success,
.toast-error {
  color: white !important;
  border-radius: 4px !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  background-color: lighten($primary, 5%);
}

.toast-error {
  background-color: lighten($error, 5%);
}

.toastProgress {
  background: white !important;
}

.Toastify__close-button {
  svg {
    fill: white;
  }
}

.Toastify__close-button--default {
  opacity: 0.8;
}

.Toastify__toast {
  min-height: 55px;
}

.mb-xl {
  margin-bottom: 20px !important;
}

.mt-sm {
  margin-top: 12px;
}

.color-orange {
  color: $orange;
}

.text-area-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.follow-up-feild {
  input {
    border: 0;
    border-bottom: 1px solid;
    margin-right: 1rem;
  }
}
.follow-up-feild-create {
  input {
    width: 100%;
    border: 1px solid #dcdada;
    padding: 13.5px;
    // margin-top: 0.3rem;
    margin-right: 1rem;
  }
}
.clear-icon {
  color: #c7c7c7 !important;
  width: 0.8em !important;
  height: 0.8em !important;
  cursor: pointer;
  &:hover {
    color: $error !important;
  }
}

.inline-block-property{
  display: inline-block;
}
