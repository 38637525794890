.client-form-wrapper {
  flex: 1;
}

.residential-details {
  position: relative;

  .residential-checkbox {
    position: absolute;
    top: rem-calc(8);
    left: 0;
    margin-left: rem-calc(150);
    display: block;
    @include media('>=tablet') {
      width: 100%;
      margin-left: rem-calc(165);
      min-width: rem-calc(220);
    }
  }

  .MuiFormControlLabel-label {
    font-size: rem-calc(14);
    min-width: rem-calc(190);
    margin-left: rem-calc(6);
  }

  .MuiSvgIcon-root {
    width: rem-calc(20);
  }

  .MuiIconButton-label {
    width: rem-calc(11);
    height: rem-calc(11);
  }
}

.MuiPaper-root {
  min-width: 0 !important;
}

.client-profile-form {
  padding-top: rem-calc(25);

  h2 {
    margin-bottom: 0;
  }

  .MuiPaper-root {
    background-color: #f9f9f9;

    .MuiBox-root {
      // padding: 0 20px;
    }
  }

  .MuiAccordionSummary-root {
    display: inline-block;
    padding: 0;

    &.Mui-expanded {
      min-height: 0;

      h3 {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
  .MuiAccordionSummary-content {
    display: block;

    &.Mui-expanded {
      margin: 0;
    }
  }

  .MuiAccordionDetails-root {
    display: block;
    padding: 0;
  }

  .MuiCollapse-wrapper {
    display: block;
  }

  .transaction-feildset {
    .MuiPaper-root {
      background-color: transparent;
    }

    .MuiPaper-elevation1 {
      box-shadow: none;
    }
  }

  .remove-btn {
    background-color: #f9f9f9;
    color: $primary;
    float: right;

    &:hover {
      background-color: #ec3232;
      border-color: #ec3232;
      color: $white;
    }
  }

  .form-buttons {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 15px;
    background-color: #e9f4ff;
    height: 74px;

    @include media('>=tablet') {
      padding: 15px 30px 10px;
    }

    &:before {
      content: '';
      position: absolute;
      top: -1px;
      left: 5px;
      right: 5px;
      border-top: 1px solid rgb(169, 224, 240);

      @include media('>=widescreen') {
        left: 0;
        right: 0;
      }
    }

    // @include media('>=tablet') {
    //  left: 150px;
    // }

    .cancel-button {
      &:hover {
        background-color: #ec3232;
        border-color: #ec3232;
      }
    }

    .form-row {
      max-width: 1086px;
      margin: 0 auto;

      @include media('>=tablet') {
        position: absolute;
        bottom: 12px;
        left: 180px;
        @include animate(left);
      }

      .sidebar-toogle & {
        @include media('>=tablet') {
          left: 110px;
        }

        @include media('>=widescreen') {
          left: 130px;
        }
      }
    }
  }

  .form-category {
    margin-bottom: rem-calc(55);
  }

  .textarea-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    padding-top: 12px;

    &.p-0 {
      padding-top: 0;
    }
  }

  textarea {
    padding: 15px;
    resize: none;
    width: 100%;
    background-color: transparent !important;
    border: 1px solid #dcdada;

    &:focus {
      border-color: $primary;
      outline: none;
    }
  }

  .MuiInputAdornment-positionStart {
    margin-left: 14px;
    margin-right: 0;
  }
  .range-feild {
    input {
      padding: 8px 10px 7px;
    }
  }

  .transaction-feild-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .transaction-feildset {
    margin: 0;
    padding: 0;

    h3 {
      font-size: 20px;
      color: $secondary;
      margin-bottom: 25px;
      position: relative;
      padding-right: 30px;
      @include animate(color);

      &:hover {
        color: $primary;

        &:after {
          color: $primary;
        }
      }

      &:after {
        content: '\e90a';
        font-family: 'icomoon';
        position: absolute;
        right: 0;
        top: 9px;
        font-size: 8px;
        @include animate(transform color);
      }
    }

    // &:first-child {
    //   h3 {
    //     display: none;
    //   }
    // }

    &:not(:last-child) {
      padding-top: 30px;
      border-top: 1px solid #dcdada !important;
      margin-top: 20px;
    }
  }

  .transaction-title {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 22px;
    align-items: center;

    .category-title {
      margin-bottom: 0;
    }

    button {
      margin-left: 25px;
      background-color: #f9f9f9;
      padding: 8px 20px;
      // max-height: 40px;
      color: $primary;

      @include media('<tablet') {
        margin: 10px 0 7px;
        width: 100%;
      }

      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .MuiFormControl-root,
  .MuiTextField-root {
    width: 100%;
  }

  .MuiInputLabel-root {
    position: relative;
    margin-bottom: 8px;
  }

  .MuiTypography-subtitle1 {
    font-size: rem-calc(14);
    font-weight: 500;
    color: #333;
  }

  .MuiFormHelperText-root {
    margin-top: rem-calc(3);
    margin-left: rem-calc(4);
  }

  .MuiInput-root {
    width: 100%;
  }

  .category-title-hold {
    margin-bottom: rem-calc(25);
  }

  .same-value {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: rem-calc(8);

    .MuiCheckbox-root {
      padding: 0 !important;
    }

    .checkbox {
      margin-left: 22px;
      padding-top: 13px;

      @include media('<tablet') {
        display: block;
        width: 100%;
        margin-left: 0;
        margin-bottom: 7px;
      }
    }

    .checkbox-label {
      font-size: 14px;
      color: #333;
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
    }

    .subtitle-label {
      display: inline-block;
      margin-bottom: 0;
      color: $primary;
    }
  }

  .form-row {
    margin-bottom: rem-calc(18);

    @include media('>=widescreen') {
      padding: rem-calc(0 30);
    }
  }

  h2 {
    color: $secondary;
    background-color: rgba(#000, 0.6);
  }

  .subtitle-label {
    display: block;
    font-size: rem-calc(14);
    color: #333;
    font-weight: 500;
    margin-bottom: rem-calc(15);
    padding-top: 15px;

    &.p-0 {
      padding-top: 0;
    }

    &.mb-lg {
      margin-bottom: rem-calc(12);
    }
  }
}

.category-title {
  color: $orange;
  font-size: rem-calc(20);
  font-weight: 500;
  display: block;
  margin-bottom: rem-calc(28);
}

.calender-feild {
  position: relative;
}

.category-title-hold {
  @include media('>=phone') {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .checkbox {
    margin-top: 2px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include media('>=phone') {
      margin-left: 15px;
    }
  }

  .referral-text {
    display: inline-block;
    margin-right: 8px;
  }

  .checkbox-label {
    display: inline-block;
    margin-left: 5px;
    font-weight: 500;
  }

  .MuiTouchRipple-root {
    display: none;
  }
}

.border-red {
  border-color: #f44336 !important;
}

.color-red {
  color: #f44336;
}

.purchase-price-range {
  h6 {
    margin-top: 8px;
  }

  .MuiGrid-item {
    padding: 0 5px !important;
  }

  input {
    text-align: left !important;
  }
}
.MuiPaper-root.MuiAccordion-root.transaction-feildset.Mui-expanded.MuiPaper-elevation1 {
  background: transparent;
  box-shadow: none;
}

.MuiPaper-root.MuiAccordion-root.transaction-feildset.MuiPaper-elevation1 {
  background: transparent;
  border: 0;
  box-shadow: none;
}

.cancel-button-holder {
  display: inline-block;

  .MuiButton-contained {
    background-color: transparent;
    border: 1px solid $primary;

    &:hover {
      background-color: $error;
      border-color: $error;
      color: $white;
    }
  }
}
