#header {
  z-index: 9;
  position: relative;
  background-color: $white;
  padding: rem-calc(15 20);
  box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.05);

  @include media('>=tablet') {
    padding: rem-calc(15 39 15 37);
    top: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    z-index: 999;
  }

  .logo-holder {
    width: 155px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include media('>=phone') {
      width: 200px;
    }

    .ganesh-logo {
      margin-left: 15px;
      color: $primary;

      span {
        display: inline-block;
        font-size: 50px;
      }
    }
  }

  .profile-picture {
    margin-left: rem-calc(25);
    display: none;

    @include media('>=tablet') {
      margin-right: rem-calc(8);
      display: inline-block;
    }

    a {
      &:hover {
        opacity: 0.85;
        cursor: pointer;
      }
    }

    .image-holder {
      width: rem-calc(35px);
      height: rem-calc(35px);
      border-radius: 50%;

      @include media('>=tablet') {
        width: rem-calc(45px);
        height: rem-calc(45px);
      }

      .MuiAvatar-root {
        width: 100%;
        height: 100%;
      }
    }
  }

  .calender-wrapper {
    cursor: pointer;
    margin-top: rem-calc(-3);
    margin-left: rem-calc(10);

    @include media('>=tablet') {
      margin-left: rem-calc(16);
      margin-top: rem-calc(-3);
    }

    a {
      color: $black;
      opacity: 0.85;

      &:hover {
        color: $primary;
      }
    }

    .icon-calender {
      font-size: rem-calc(23);
    }

    .icon-arrow-down {
      font-size: rem-calc(7);
      display: none;

      @include media('>=tablet') {
        display: inline-block;
      }
    }

    span {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }
  }

  .create-profile {
    display: flex;
    align-items: center;
    margin-left: rem-calc(15);
    position: relative;

    @include media('>=phone') {
      margin-left: rem-calc(20);
    }

    .tooltip {
      font-size: 10px;
      padding: 5px 10px 6px;
      color: #fff;
      border-radius: 5px;
      background-color: #333;
      line-height: 1;
      position: absolute;
      width: 85px;
      left: 50%;
      bottom: 100%;
      margin-bottom: 2px;
      transform: translateX(-50%);
      opacity: 0;
      visibility: hidden;
      @include animate(opacity visibility);

      &:after {
        content: '';
        position: absolute;
        top: 99%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #333 transparent transparent transparent;
      }
    }

    .icon-plus-circle {
      font-size: rem-calc(25);
    }

    a {
      color: $secondary;

      &:hover {
        color: $primary;

        .tooltip {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.logo {
  width: rem-calc(88);

  @include media('>=phone') {
    width: rem-calc(100);
  }

  @include media('>=tablet') {
    width: rem-calc(120);
  }
}

.top-search-form {
  display: none;
  @include media('>=tablet') {
    margin-top: rem-calc(-2);
    display: inline-block;
  }

  .form-control {
    position: relative;
  }

  input {
    border-radius: rem-calc(10);
    height: rem-calc(38);
    background: rgba(75, 92, 101, 0.2);
    border: 0;
    padding: rem-calc(8 41);
    width: 185px;

    @include placeholder {
      text-transform: uppercase;
      color: rgba(#000, 0.7);
    }
  }

  .icon-search,
  .icon-arrow-down {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: rem-calc(15);
    font-size: rem-calc(15);
  }

  .icon-arrow-down {
    left: auto;
    font-size: rem-calc(8);
    right: rem-calc(15);
  }
}

.nav-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include media('>=tablet') {
    align-items: center;
    justify-content: flex-start;
  }
}

/* style for NAV */
#nav {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.opener {
  display: block;
}

.opener {
  width: 28px;
  height: 40px;
  position: relative;
  border-radius: rem-calc(0);
  text-indent: -9999px;
  overflow: hidden;
  border: 0;
  background: transparent;

  @include media('>=tablet') {
    width: 35px;
    height: 40px;
  }

  &:hover {
    cursor: pointer;
  }

  &:before,
  &:after,
  span {
    background: $primary;
    border-radius: 0;
    position: absolute;
    top: rem-calc(20);
    right: 0;
    left: 25%;
    height: rem-calc(3);
    margin-top: rem-calc(-2);
    transition: all 0.2s linear;

    @include media('>=tablet') {
      background: $white;
      right: 35%;
      left: 20%;
    }

    .resize-active & {
      transition: none;
    }
  }

  &:before {
    left: 0;

    @include media('>=tablet') {
      right: 20%;
      left: 20%;
    }
  }

  &:before,
  &:after {
    content: '';
    top: rem-calc(10);
  }

  &:after {
    top: rem-calc(31);
    left: 15%;

    @include media('>=tablet') {
      right: 20%;
      left: 20%;
      top: 30px;
    }
  }
}

.sidebar-toogle {
  .opener {
    span {
      opacity: 0;
    }
  }
}

.sidebar-toogle {
  .opener {
    &:before {
      transform: rotate(45deg);
      top: rem-calc(20);
      left: 5%;
      right: 5%;
      @include media('>=tablet') {
        top: rem-calc(20);
        left: 15%;
        right: 15%;
      }
    }
  }
}

.sidebar-toogle {
  .opener {
    &:after {
      transform: rotate(-45deg);
      top: rem-calc(20);
      left: 5%;
      right: 5%;

      @include media('>=tablet') {
        top: rem-calc(20);
        left: 15%;
        right: 15%;
      }
    }
  }
}

#user-menu {
  .MuiPopover-paper {
    @include media('>=tablet') {
      top: 70px !important;
    }
  }

  .MuiPaper-elevation8 {
    @include media('<tablet') {
      box-shadow: none;
    }
  }

  .MuiMenuItem-root {
    padding: 5px 20px;
  }

  .MuiMenu-list {
    width: 200px !important;
  }
}

.MuiDialog-paper {
  z-index: 9;
}

.Dialog-box-wrapper {
  padding: 30px 50px 30px 30px;
  position: relative;

  h3 {
    margin-bottom: 30px;
  }
  .cross-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .cancel-button {
    &:hover {
      border-color: #f44336;
      background-color: #f44336;
    }
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 25px;
  }

  .MuiInputLabel-formControl {
    position: relative;
    width: 100%;
  }
}

.MuiDialog-root {
  .MuiBackdrop-root {
    background-color: rgba(#333, 0.5) !important;
  }
}

.searchFormDiv {
  position: relative;
}

.searchContainer {
  position: absolute;
  top: 3.25rem;
  background-color: #fff;
  width: 270px;
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 1rem;
  transition: all 0.12s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  padding: 20px 0;
  max-height: 350px;

  .link {
    color: $black;
    display: block;
    padding: 12px 20px;
    background-color: transparent;

    &:hover {
      background-color: $secondary;
      color: $white;
    }

    .arrow {
      display: none;
    }
  }

  .no-data-label {
    color: #f44336;
    padding: 0 20px;
  }
}
.tab-panel-details {
  .tab-panel-details-tran {
    padding-top: 20px;
    margin-top: 40px;
    border-top: 1px solid #0000004f;
  }
}
