.client-detail-wrapper {
  .user-other-details {
    box-shadow: 5px 6px 18px rgba(0, 0, 0, 0.15);
    padding: 30px 20px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    strong {
      font-weight: 500;
    }

    .details {
      padding: 0 15px;
      color: #545353;
      width: 100%;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      @include media('>=phone') {
        width: 50%;
        margin-bottom: 0;
      }
    }
  }
  .username {
    padding-top : 10px;
  }
  .emergency-contact {
    box-shadow: 5px 6px 18px rgba(0, 0, 0, 0.15);
    padding: 20px 20px 30px;

    h4 {
      margin-bottom: 30px;
    }

    .contact-info {
      display: flex;
      flex-wrap: wrap;
      word-break: break-all;

      &:last-child {
        .info-holder {
          margin-bottom: 0;
        }
      }

      .icon-holder {
        width: 38px;
        flex-basis: 38px;

        svg {
          color: #797979;
        }

        &.email-icon {
          .MuiSvgIcon-root {
            width: 22px !important;
            margin-top: -2px;
          }
        }

        &.user-icon {
          .MuiSvgIcon-root {
            margin-top: -3px;
          }
        }
      }

      .info-holder {
        flex: 1;
        color: #545353;
        margin-bottom: 15px;

        a {
          color: #545353;
          font-weight: normal;

          &:hover {
            color: $primary;
          }
        }

        strong {
          font-weight: 500;
        }

        address {
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          word-break: break-all;
        }

        span {
          display: block;
          margin-bottom: 8px;
        }
      }
    }
  }
}
