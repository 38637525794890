.client-detail-wrapper {
  flex: 1;

  .name-status {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    @include media('>=tablet') {
      margin-bottom: 0;
      max-width: calc(100% - 140px);
    }

    h3 {
      margin-bottom: 5px;
      line-height: 1;
      margin-right: 22px;
      text-transform: capitalize;
    }

    .status {
      display: inline-block;
      position: relative;
      align-self: bottom;
      padding-left: 23px;
      margin-bottom: 3px;
      padding-right: 15px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #05dc5e;
      }

      &.status-inactive {
        &:before {
          background-color: $error;
        }
      }
      &.status-others {
        &:before {
          background-color: $secondary;
        }
      }
    }

    .entryDate {
      display: inline-block;
      position: relative;
      align-self: bottom;
      // padding-left: 23px;
      margin-bottom: 3px;
    }
  }

  .top-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
}

.client-personal-detail {
  box-shadow: 5px 6px 18px rgba(0, 0, 0, 0.15);
  padding: 30px 20px;
  word-break: break-all;

  .top-col {
    text-align: center;
    margin-bottom: 30px;

    h4 {
      margin-bottom: 7px;
      text-transform: capitalize;
      word-break: break-all;
    }
  }

  .client-type {
    color: $orange;
    font-size: 18px;
    font-weight: 500;
  }

  .image-holder {
    width: 167px;
    margin: 0 auto 20px;

    img {
      max-width: 100%;
    }
  }
  .avatar-holder {
    background-repeat: no-repeat !important;
    background-size: cover;
    width: 170px;
    height: 170px;
    margin: auto;
    margin-bottom: 0.5rem;

    .avatarImageCropper {
      opacity: 0;
      transition: all 0.1s ease-in-out;
    }
    &:hover .avatarImageCropper {
      opacity: 1;
    }
    .active {
      opacity: 1;
    }
  }

  .contact-info {
    display: flex;
    flex-wrap: wrap;

    &:last-child {
      .info-holder {
        margin-bottom: 0;
      }
    }

    .icon-holder {
      width: 38px;
      flex-basis: 38px;

      svg {
        color: #797979;
      }

      &.email-icon {
        .MuiSvgIcon-root {
          width: 22px !important;
          margin-top: -2px;
        }
      }

      &.user-icon {
        .MuiSvgIcon-root {
          margin-top: -3px;
        }
      }
    }

    .info-holder {
      flex: 1;
      color: #545353;
      margin-bottom: 15px;

      a {
        color: #545353;
        font-weight: normal;

        &:hover {
          color: $primary;
        }
      }

      strong {
        font-weight: 500;
      }

      address {
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        word-break: break-all;
        line-height: 1.75;
      }

      span {
        display: block;
        margin-bottom: 8px;
      }
    }
  }
}

.note-list-holder {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.note-lists {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  .blur {
    opacity: 0.5;
  }

  .lists {
    background-color: #f9f9f9;
    cursor: pointer;
    padding: 28px 22px 10px;
    position: relative;
    margin-bottom: 15px;
    width: 100%;

    &:hover {
      .rightIcon {
        opacity: 1;
        visibility: visible;
      }
    }

    .rightIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      display: flex;
      flex-wrap: wrap;
      opacity: 0;
      visibility: hidden;
      @include animate(visibility opacity);
      z-index: 2;

      .icon-holder {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: linear-gradient(145deg, #e0e0e0, #ffffff);
        box-shadow: 6px 6px 17px #efefef, -6px -6px 17px #ffffff;
        margin-left: 15px;

        &:hover {
          svg {
            color: $error;
          }

          svg {
            &.edit-icon {
              color: $primary;
            }
          }
        }

        svg {
          color: $secondary;
          font-size: 23px;
          @include animate(color);

          &.edit-icon {
            font-size: 22px;
          }
        }
      }
    }

    &:first-child {
      margin-bottom: 0;
    }

    &:nth-child(even) {
      background-color: #f0fbfe;

      .icon-holder {
        background: linear-gradient(145deg, #d8e2e5, #ffffff);
        box-shadow: 6px 6px 28px #cae2ea, -6px -6px 17px #fff;
      }
    }

    p {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .username {
    color: #402795;
    font-weight: 500;
    display: inline-block;
    margin-right: 3px;
  }

  strong {
    font-weight: 500;
  }

  .list-bottom {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .posted-date {
    font-size: 12px;

    @include media('<phone') {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  .follow-up-date {
    background-color: $orange;
    color: $white;
    text-align: center;
    display: inline-block;
    padding: 4px 20px 4px 10px;
    position: absolute;
    font-size: 14px;
    top: 0;
    left: 0;
    border-bottom-right-radius: 28px;

    svg {
      vertical-align: middle;
    }

    .color-check-circle {
      color: $primary;
    }

    strong {
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}

.client-note-detail {
  box-shadow: 5px 6px 18px rgba(0, 0, 0, 0.15);
  padding: 30px 40px;
  height: 100%;

  .client-note-form {
    margin-bottom: 30px;
  }

  h3 {
    margin-bottom: 25px;
    display: inline-block;
  }

  .note-date {
    position: relative;
    .MuiFormControl-root {
      width: 100%;
      @include media('>=tablet') {
        width: 175px;
      }
    }

    .MuiInput-root {
      height: 32px;
    }

    .icon-calender {
      position: absolute;
      left: 2px;
      top: 2px;
      font-size: 30px;
      z-index: -1;
      color: $secondary;
    }

    @include placeholder {
      color: red;
    }
  }

  .form-buttons {
    .MuiButton-root {
      padding: 3px 13px 3px 15px;
    }

    .form-row {
      @include media('>=tablet') {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }
  }

  .cancel-button {
    &:hover {
      background-color: $error;
      border-color: $error;
    }
  }

  .image-holder {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    // height: 100%;
  }

  .form-holder {
    margin-bottom: 15px;
    @include media('>=tablet') {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
    }
    .form-control {
      position: relative;
      height: 100%;
      @include media('>=tablet') {
        flex: 1;
        align-self: center;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
      }
    }

    .MuiFormControl-root {
      label {
        display: none;
      }

      .MuiInput-root {
        height: 32px;
        border-width: 0 0 1px 0;
        border-color: #555353;
        margin-bottom: 10px;
        width: 100%;
        @include media('>=tablet') {
          margin-bottom: 0;
          width: 160px;
        }

        &.Mui-focused {
          border-color: $primary;
        }

        &:hover,
        &:focus {
          outline: none;
        }
      }

      .MuiInput-input {
        padding: 5px 10px;
      }
    }
  }

  textarea {
    border-width: 0 0 1px 0;
    resize: none;
    border-color: hsl(0, 1%, 33%);
    margin-top: 1px;
    padding: 6px 60px 6px 12px;
    width: 100%;
    margin-bottom: 10px;
    @include media('>=tablet') {
      margin-right: 20px;
      padding: 6px 60px 6px 5px;
      width: calc(100% - 200px);
      margin-bottom: 0;
    }
    &:hover,
    &:focus {
      outline: none;
      border-color: $primary;
    }
  }

  // .note-lists {
  //   max-height: 300px;
  //   overflow-y: auto;

  //   .lists {
  //     background-color: #f9f9f9;
  //     padding: 38px 30px 10px;
  //     position: relative;
  //     margin-bottom: 20px;

  //     &:last-child {
  //       margin-bottom: 0;
  //     }

  //     &:nth-child(even) {
  //       background-color: #f0fbfe;
  //     }

  //     p {
  //       margin-bottom: 5px;
  //     }
  //   }

  //   .username {
  //     color: #402795;
  //     font-weight: 500;
  //     display: inline-block;
  //     margin-right: 3px;
  //   }

  //   strong {
  //     font-weight: 500;
  //   }

  //   .list-bottom {
  //     font-size: 12px;
  //     display: flex;
  //     flex-wrap: wrap;
  //     justify-content: space-between;
  //   }
  // }

  .posted-date {
    font-size: 12px;
  }

  .follow-up-date {
    background-color: $orange;
    color: $white;
    text-align: center;
    display: inline-block;
    padding: 3px 20px 3px 10px;
    position: absolute;
    font-size: 14px;
    top: 0;
    left: 0;
    border-bottom-right-radius: 28px;
  }
}

.other-details {
  max-width: calc(100vw - 40px);
  margin-top: 40px;

  @include media('>=tablet') {
    max-width: calc(100vw - 200px);
  }

  @include media('>=desktop') {
    max-width: calc(100vw - 280px);
  }

  .MuiBox-root {
    @include media('<tablet') {
      padding: 30px 5px !important;
    }
  }

  .note-lists {
    scrollbar-width: thin;

    .lists {
      padding: 20px 30px;
    }
  }

  .MuiAppBar-colorDefault {
    background-color: #f7f7f778 !important;
  }

  .MuiAppBar-colorPrimary {
    background-color: #f0f0f0 !important;
    box-shadow: none !important;
    color: #333;
  }

  .MuiTab-textColorInherit {
    opacity: 1 !important;
  }

  .Mui-selected {
    background-color: #fff;
    color: $primary;
  }

  .MuiTabs-root {
    position: relative;
  }

  .MuiTabScrollButton-root {
    &:first-child,
    &:last-child {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      background-color: $secondary;
      z-index: 2;
      height: 20px;
      border-radius: 50%;

      svg {
        fill: $white;
      }
    }

    &:last-child {
      left: auto;
      right: 5px;
    }
  }

  .file-uploads {
    margin-bottom: 20px;
  }

  .file-notes {
    .form-control {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;

      @include media('>=tablet') {
        max-width: 55%;
      }

      button {
        height: 100%;
      }
    }

    .details-heading {
      align-items: flex-end;
    }

    textarea {
      border-width: 0 0 1px 0;
      resize: none;
      border-color: hsl(0, 1%, 33%);
      margin-right: 20px;
      margin-top: 1px;
      padding: 5px 10px;
      flex: 1;

      @include media('<phone') {
        margin-bottom: 10px;
        width: 100%;
        flex: auto;
        margin-top: 15px;
      }

      @include media('>=phone') {
        padding: 6px 20px 6px 5px;
      }

      &:hover,
      &:focus {
        outline: none;
        border-color: $primary;
      }
    }
  }

  .file-uploads-heading {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .detail-accordion-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .title {
    font-weight: 500;
  }

  .details-heading {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 5px 15px;
    }

    &.file-uploads-heading {
      h4 {
        margin-bottom: 10px;

        @include media('>=tablet') {
          margin-bottom: 0;
        }
      }
    }
  }

  .details-list {
    .list {
      margin-bottom: 10px;
    }

    .title {
      display: inline-block;
      font-weight: 500;
    }
  }

  .file-lists {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    margin: 25px -30px 0;

    .list {
      text-align: center;
      padding: 0 30px;
      width: 200px;
      margin-bottom: 30px;
    }

    svg {
      font-size: 80px;
      color: #bed643;
    }

    .icon-holder {
      margin-bottom: 10px;
    }

    .filename {
      display: block;
      text-align: center;
      word-break: break-all;
      font-size: 14px;
    }
  }

  .acc-holder {
    margin-bottom: 15px !important;

    &:hover {
      .MuiAccordionSummary-content {
        h4 {
          color: $primary;
        }
      }

      .MuiIconButton-root {
        color: $primary;
      }
    }

    .MuiAccordionSummary-content {
      h4 {
        @include animate(color);
      }
    }

    .MuiIconButton-root {
      @include animate(color);
    }
  }

  .MuiAccordion-root {
    &:before {
      display: none;
    }

    &.Mui-expanded {
      .MuiAccordionSummary-content {
        h4 {
          color: $primary !important;
        }
      }

      .MuiIconButton-root {
        color: $primary;
      }
    }
  }

  .detail-content-accordion {
    .MuiAccordionDetails-root {
      display: block;

      h5 {
        color: $orange;
      }
    }

    .MuiPaper-elevation1 {
      box-shadow: none;
      border: 1px solid #d2d2d2;
    }

    .MuiAccordionSummary-content {
      h4 {
        margin-bottom: 0;
        font-size: 18px;
      }
    }
  }
}

.detail-page-dialog {
  .MuiDialog-paper {
    padding: 30px;
    position: relative;
    max-width: none !important;

    @include media('<tablet') {
      width: 100%;
    }

    .MuiInput-root {
      width: 100%;
    }

    .subtitle-label {
      color: #333;
      display: block;
      font-size: 14px;
      font-weight: 500;
    }

    @include media('>=tablet') {
      min-width: 700px !important;
      max-width: 700px !important;
    }

    @include media('>=desktop') {
      min-width: 800px !important;
      max-width: 900px !important;
    }
  }

  .form-row {
    margin-bottom: 10px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .cross-button {
    position: absolute;
    top: 15px;
    right: 20px;
  }

  .form-buttons {
    margin-top: 20px;
  }

  h4 {
    margin-bottom: 30px;
    margin-right: 60px;
  }
}

.fileCorrespondingDelete {
  margin-left: auto;
  color: $secondary;
  @include animate(color);
  display: inline-block;

  &:hover {
    color: $error;
  }
}
.file-uploads-container {
  padding: 8px 0px;
  text-transform: capitalize;

  &-1 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    margin-bottom: 8px;
    padding: 5px;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: rgba($black, 0.85);
    justify-content: space-between;
  }
}
.file-uploads-one {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: flex-end;
  &.file-uploads-two {
    min-width: 150px;
  }
}

.file-view-btn {
  color: $primary;
  background-color: transparent;
  border: 0;
  &:hover {
    opacity: 0.75;
  }
}
.file-uploads-one-btn-1 {
  a {
    color: rgba($black, 0.8);

    &:hover {
      color: $primary;
    }
  }
}

.file-uploads-one-btn-2 {
  color: $error;
  margin-left: 10px;

  &:hover {
    color: lighten($error, 10%);
  }
}
.file-uploads-one-btn-1,
.file-uploads-one-btn-2 {
  border: none;
  transition: all 0.2s ease;
  background-color: transparent;
}

.file-uploads-one-icon {
  margin-right: 5px;
  color: rgba($black, 0.8);
}

.file-uploads-container-1-uploadDate {
  color: rgb(145, 145, 145);
}
.file-uploads-container-1-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
.file-uploads-one {
  // width: 50%;
  // overflow: hidden;
}
.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding: 1px 40px 1px 7px !important;

  @include media('<tablet') {
    padding: 1px 40px 1px 13px !important;
  }
}
.detail-content-accordion-title {
  .MuiAccordionSummary-content {
    &:nth-of-type(1) {
      display: flex !important;
      align-items: center !important;
      flex-wrap: wrap;
      margin: 20px 0;
    }
  }

  h4 {
    width: calc(100% - 30px);
  }
}
.edit-delete-wraper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .edit-delete{
    display:flex;
    flex-wrap:nowrap;
  }
}
.margindelete {
  margin-left: 12px;
}

.edit-icons:hover {
  color: $primary;
}
