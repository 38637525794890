@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot?pcgodw');
  src: url('../../fonts/icomoon.eot?pcgodw#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?pcgodw') format('truetype'), url('../../fonts/icomoon.woff?pcgodw') format('woff'),
    url('../../fonts/icomoon.svg?pcgodw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ganesh-logo:before {
  content: '\e90d';
}
.icon-ganesh-logo-old:before {
  content: '\e90c';
}
.icon-plus-circle:before {
  content: '\e904';
}
.icon-check:before {
  content: '\e900';
}
.icon-close:before {
  content: '\e901';
}
.icon-upload:before {
  content: '\e902';
}
.icon-envelope:before {
  content: '\e903';
}
.icon-search:before {
  content: '\e905';
}
.icon-plus:before {
  content: '\e906';
}
.icon-profile:before {
  content: '\e907';
}
.icon-dashboard:before {
  content: '\e908';
}
.icon-event:before {
  content: '\e909';
}
.icon-arrow-down:before {
  content: '\e90a';
}
.icon-calender:before {
  content: '\e90b';
}
